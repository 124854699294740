
const utm = '?utm_source=auto-expert-moscow&utm_medium=organic&utm_campaign=otzovik&utm_content=button';

const createLink = (base) => `${base}${utm}`;

export const otzovikList = [
  {
    title: 'автосалон отзывы',
    link: createLink('https://xn----7sbahc3a0aqgcc4ali0lb.xn--p1ai/avtosalon-avto-ekspert-otzyvy-pokupatele'),
  },
  {
    title: 'авто отзовик',
    link: createLink('https://auto-otzovik.ru/avtosalon/avtosalon-avto-ekspert-otzyvy-pokupatele'),
  },
  {
    title: 'топ отзывов',
    link: createLink('https://xn----dtbas7abcdze4h.xn--p1ai/avtosalon-avto-ekspert-otzyvy-pokupatele'),
  },
  {
    title: 'отзыв авто',
    link: createLink('https://autoreview-help.ru/avtosalon-avto-ekspert-otzyvy-pokupatele'),
  },
  {
    title: 'авто отзыв',
    link: createLink('https://auto-review.info/avtosalon/otzyvy-ob-avtosalone-auto-expert'),
  },
];
